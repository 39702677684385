/* BrandForm.css */
.brand-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .brand-form-container {
    background-color: #fff;
    padding: 20px;
    width: 500px;
    max-width: 90%;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .brand-form-container h3 {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  .form-input {
    width: 100%;
    padding: 8px 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-input:focus {
    border-color: #66afe9;
    outline: none;
  }
  
  .fields-list {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
  }
  
  .field-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .field-item-checkbox {
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  .field-item-checkbox input[type='checkbox'] {
    margin-right: 8px;
  }
  
  .field-item-order {
    display: flex;
    align-items: center;
  }
  
  .field-item-order label {
    margin-right: 5px;
  }
  
  .field-order-input {
    width: 60px;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-actions {
    text-align: right;
    margin-top: 20px;
  }
  
  .save-button,
  .cancel-button {
    padding: 8px 16px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-button {
    background-color: #28a745;
    color: #fff;
  }
  
  .save-button:hover {
    background-color: #218838;
  }
  
  .cancel-button {
    background-color: #dc3545;
    color: #fff;
  }
  
  .cancel-button:hover {
    background-color: #c82333;
  }
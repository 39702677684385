.pattern-lock {
    border: 1px solid #ccc;
    touch-action: none; /* Prevent default touch behavior */
  }
  
  .pattern-lock circle {
    pointer-events: none; /* Dots should not capture events */
  }
  
  .form-group {
    margin-bottom: 16px;
  }
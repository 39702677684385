/* App.css */

/* Global Styles */
body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

/* App Container */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Header Container */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Left Section */
.header-left {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-image {
  height: 40px;
  width: 40px;
  margin-right: 12px;
}

.app-title {
  margin: 0;
  font-size: 24px;
  color: #333;
  font-weight: 600;
}

/* Right Section */
.header-right {
  display: flex;
  align-items: center;
}

.navigation {
  display: flex;
  margin-right: 24px;
}

.nav-link {
  margin-right: 16px;
  text-decoration: none;
  color: #555;
  font-size: 16px;
  transition: color 0.2s;
}

.nav-link:last-child {
  margin-right: 0;
}

.nav-link:hover {
  color: #000;
}

.nav-link.active {
  color: #000;
  font-weight: 600;
  border-bottom: 2px solid #3f51b5;
}

.language-selector {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navigation {
    display: none;
  }
  
  .header-right {
    flex-direction: row-reverse;
  }
}

/* Menu Toggle Button */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 16px;
}

.hamburger {
  width: 24px;
  height: 2px;
  background-color: #555;
  position: relative;
  transition: all 0.3s;
}

.hamburger::before,
.hamburger::after {
  content: '';
  width: 24px;
  height: 2px;
  background-color: #555;
  position: absolute;
  left: 0;
  transition: all 0.3s;
}

.hamburger::before {
  top: -8px;
}

.hamburger::after {
  top: 8px;
}

/* Toggle menu open state */
.menu-toggle.open .hamburger {
  background-color: transparent;
}

.menu-toggle.open .hamburger::before {
  transform: rotate(45deg);
  top: 0;
}

.menu-toggle.open .hamburger::after {
  transform: rotate(-45deg);
  top: 0;
}

/* Responsive Navigation */
@media (max-width: 768px) {
  .navigation {
    position: absolute;
    top: 64px;
    right: 0;
    background-color: #ffffff;
    flex-direction: column;
    width: 200px;
    border: 1px solid #e0e0e0;
    display: none;
  }

  .navigation.open {
    display: flex;
  }

  .nav-link {
    margin: 16px;
  }

  .menu-toggle {
    display: block;
  }

  .header-right {
    align-items: center;
  }
}

/* Main Content */
.main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.home-page {
  display: flex;
  flex: 1;
  overflow: hidden;
}

/* Sidebar */
.sidebar {
  width: 250px;
  background-color: #f9f9f9;
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
  padding: 16px;
}

.sidebar-title {
  margin-top: 0;
  font-size: 18px;
  margin-bottom: 16px;
}

.brand-list {
  display: flex;
  flex-direction: column;
}

.brand-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.brand-item:hover {
  background-color: #e0e0e0;
}

.brand-icon {
  width: 32px;
  height: 32px;
  border-radius: 4px;
}

.brand-text {
  margin-left: 12px;
  font-size: 16px;
}

/* Content Area */
.content-area {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.account-sheet {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.remove-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #888;
  transition: color 0.2s;
}

.remove-button:hover {
  color: #f44336;
}

.sheet-content {
  display: flex;
  flex-direction: column;
}

.sheet-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.brand-logo {
  width: 48px;
  height: 48px;
  border-radius: 4px;
}

.brand-name {
  margin-left: 16px;
  font-size: 20px;
}

.sheet-form {
  display: flex;
  flex-direction: column;
}

.form-label {
  margin-bottom: 12px;
  font-size: 14px;
}

.form-input {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.form-input:focus {
  border-color: #3f51b5;
  outline: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
  }
}

.optionbar {
  width: 250px;
  background-color: #f9f9f9;
  border-left: 1px solid #e0e0e0;
  overflow-y: auto;
  padding: 16px;
}

.option-button {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  transition: background-color 0.2s;
}

.option-button:hover {
  background-color: #e0e0e0;
}

.admintopmenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

@media print {
  .print-page {
    margin: 0;
    padding: 20px;
    font-size: 14px;
    line-height: 1.6;
    color: #000;
    position: relative;
    min-height: 100vh; /* Ensure the print page covers full height */
  }

  .watermark-image-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0.1; /* Ensures the watermark is faint */
  }

  /* Hide unwanted elements */
  button, .sidebar, .optionbar {
    display: none;
  }

  /* Ensure the account sheets are well-formatted */
  .print-account-sheet {
    page-break-inside: avoid;
    margin-bottom: 30px;
  }

  /* Brand logo formatting */
  .print-account-sheet img {
    max-width: 50px;
    max-height: 50px;
  }

  .print-account-sheet h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #000;
  }

  /* Adjust SVG container size for printing to avoid cropping */
  .print-pattern-display {
    margin-bottom: 20px;
    overflow: visible;
  }

  /* Adjust the SVG size for printing */
  .pattern-lock {
    width: 180px;  /* Reduced size for better fit */
    height: 180px; /* Reduced size to avoid cutting off */
    margin: 0 auto;
    display: block;
  }

  /* Style for pattern dots */
  .pattern-lock circle {
    stroke: black;
    stroke-width: 1px;
  }

  /* Avoid breaking pattern in half between pages */
  .pattern-lock-container {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  /* Uploaded images positioning and styling */
  .left-image-container,
  .right-image-container {
    position: absolute;
    bottom: 20px; /* Ensure image is at the very bottom */
  }

  .left-image-container {
    left: 20px;
    width: 6rem;
    height: 6rem;
  }

  .right-image-container {
    right: 20px;
    width: 6rem;
    height: 6rem;
  }

  /* Prevent page break in account sheets */
  .account-sheet {
    page-break-inside: avoid;
  }
}